import React from 'react';
import './micrositeDown.css'
const MicrositeDown = () => {
  

  return (
    <div className="microsite-down">
      <div className="microsite-logo-container">
        <img src={'/media/images/WFLogo-Bluebg.svg'} alt="Workfreeli Logo" className="microsite-logo" />
      </div>
      <p className="microsite-message">
        It looks like you’re using a mobile browser. Our app is currently optimized for desktop and laptop browsers only.
        We’re also working on a mobile app and a mobile-friendly microsite, so stay tuned for updates.
      </p>
      <p className="microsite-message">
        For now, please visit us from a desktop or laptop for the best experience.
      </p>
      <p className="microsite-message">Thank you for your patience and understanding!</p>
    </div>
  );
};

export default MicrositeDown;
